import React, { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

export default function Form() {
    const form = useRef();

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [companyname, setCompanyName] = useState('')
    const [investmenttype, setInvestmentType] = useState('')
    const [investmentsize, setInvestmentSize] = useState('')
    const [companyoverview, setCompanyOverview] = useState('')
    const [investmentpurpose, setInvestmentPurpose] = useState('')
    const [businessplan, setBusinessPlan] = useState('')
    const [financialstatements, setFinancialStatements] = useState('')
    const [investmentdeck, setInvestmentDeck] = useState('')
    const [check, setCheck] = useState(true)
    const [thanks, setthanks] = useState(false)
    const [wobble, setWobble] = React.useState(0)
    const [thanksMessage, setthanksMessage] = React.useState('')
    const [addContact, setaddContact] = useState(true);
    const [isloading, setIsloading] = useState(false)


    var modeToggle = (check == false ? "checked" : '')
    function checkHandler() {
        if (setCheck == false) {
            setCheck(true)
        } else {
            setCheck(false)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setthanks(false)
            setaddContact(true)
        }, 3000)
        // console.log('effect', thanks)

    }, [thanks])

    const [investorTypes, setInvestorTypes] = useState({
        types: []
    });

    const handleChange = async (e) => {
        const { value, checked } = e.target;
        const { types } = investorTypes;

        if (checked) {
            await setInvestorTypes({
                types: [...types, value],
            });
        }
        else {
            await setInvestorTypes({
                types: types.filter(
                    (e) => e !== value
                )
            });
        }
    };
    function sendEmail(e) {
        e.preventDefault();
        setIsloading(true)
        let err = 0;
        const templateParams = {
            name: name,
            email: email,
            mobile: mobile,
            companyname: companyname,
            investmenttype: investmenttype,
            investmentsize: investmentsize,
            investmentsize: investmentsize,
            companyoverview: companyoverview,
            investmentpurpose: investmentpurpose,
            businessplan: businessplan,
            financialstatements: financialstatements,
            investmentdeck: investmentdeck,
            check: check,
        };

        emailjs.sendForm('service_p276kqi', 'template_9fehqlq', form.current, 'frZ2SAQj4IGorKb-D')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                const label = 'Thank You, Submit Successfully';
                setthanksMessage('Thank you for your interest. We’re excited to explore potential investment opportunities with you.')
                setthanks(true)
                setTimeout(() => {
                    setIsloading(false)
                }, 3000)        

            }, (err) => {
                console.log('FAILED...', err);
                const label = 'Something went wrong';
                setthanksMessage('Something Went Wrrong !!!!!!')
                setthanks(true)
            });
        setWobble(1)
        setName("")
        setCompanyName("")
        setInvestmentType("")
        setInvestmentSize("")
        setCompanyOverview("")
        setInvestmentPurpose("")
        setBusinessPlan("")
        setFinancialStatements("")
        setInvestmentDeck("")
        setCheck("")
        setEmail('')
        setMobile('')
        setCheck(true)
    };



    return (
        <div>
            <form action="" className=" relative" ref={form} onSubmit={sendEmail}>
                {thanks == true ? (
                    <div className='container'>
                        <div className='h-[200px]'>
                            <div className='bg-[#1b1b1b] text-[#fff] p-[30px]' onAnimationEnd={() => setWobble(0)}
                                wobble={wobble}>
                                <p className=' text-xl '>{thanksMessage}</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className='form-bg-image'></div>
                        <div className='border relative sm:p-[1.5rem] p-[1rem] dark-bg'>
                            <div>
                                <p className='md:text-[2.25rem] sm:text-[1.875rem] text-[1.5rem] text-[#d9d9d9] font-medium text-center'>Investment Inquiry Form</p>
                                <p className='sm:text-[18px] 2lg:leading-[1.8] leading-[1.4] text-center text-[#d9d9d9]'>
                                    At Kapital B Family Office, we explore investment opportunities that harmonize with our strategic approaches in Asset Management, Private Equity, Venture Capital, and Future Capital. We invite you to submit your application for our consideration by providing the details below.
                                </p>
                                <div className='w-full h-[.0625rem] bg-[#d9d9d978] my-[1.375rem]'></div>
                            </div>
                            <div className='md:grid md:grid-cols-2'>
                                <div className='col-span-1'>
                                    <p className='sm:text-[1.5rem] text-[1.125rem] text-[#d9d9d9] font-medium mb-[.5rem]'>Personal Information</p>
                                </div>
                                <div className='col-span-2'>
                                    <div className='sm:grid grid-cols-2 gap-[1rem]'>
                                        <div className='col-span-1'>
                                            <div className="">
                                                <label htmlFor="text" className="block sm:mb-2 my-2 text-sm font-medium text-[#d9d9d9]">Full Name</label>
                                                <input onChange={(e) => { setName(e.target.value) }} value={name} type="text" name='name' id="text" className="bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block w-full p-2.5" placeholder="Enter your name" required />
                                            </div>
                                        </div>
                                        <div className='col-span-1'>
                                            <div className="">
                                                <label htmlFor="email" className="block sm:mb-2 my-2 text-sm font-medium text-[#d9d9d9]">Email Address</label>
                                                <input onChange={(e) => { setEmail(e.target.value) }} value={email} type="email" name="email" id="email" className="bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block w-full p-2.5" placeholder="Enter your email" required />
                                            </div>
                                        </div>
                                        <div className='col-span-1'>
                                            <div className="">
                                                <label htmlFor="tel" className="block sm:mb-2 my-2 text-sm font-medium text-[#d9d9d9]">Contact Number</label>
                                                <input onChange={(e) => { setMobile(e.target.value) }} value={mobile} type="tel" name='number' id="tel" className="bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block w-full p-2.5" placeholder="Enter your number" required />
                                            </div>
                                        </div>
                                        <div className='col-span-1'>
                                            <div className="">
                                                <label htmlFor="text" className="block sm:mb-2 my-2 text-sm font-medium text-[#d9d9d9]">Company/Organization Name</label>
                                                <input onChange={(e) => { setCompanyName(e.target.value) }} value={companyname} type="text" name='organization_name' id="text" className="bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block w-full p-2.5" placeholder="Enter your text" required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full h-[.0625rem] bg-[#d9d9d978] my-[1.375rem]'></div>
                            <div className='md:grid md:grid-cols-2'>
                                <div className='col-span-1'>
                                    <p className='sm:text-[1.5rem] text-[1.125rem] text-[#d9d9d9] font-medium mb-[.5rem]'>Investment Details</p>
                                </div>
                                <div className='col-span-2'>
                                    <div className='sm:grid grid-cols-2 gap-[1rem]'>
                                        <div className='col-span-1'>
                                            <div className="">
                                                <label htmlFor="countries" className="block sm:mb-2 my-2 text-sm font-medium text-[#d9d9d9]">Investment Type</label>
                                                <select onChange={(e) => { setInvestmentType(e.target.value) }} value={investmenttype} id="countries" name='investment_type' className="placeholder:text-red-300 bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block w-full p-2.5 " placeholder='Choose Type' required>
                                                    <option defaultValue className='text-[#1b1b1b]' >
                                                        Select an option
                                                    </option>
                                                    <option className='text-[#1b1b1b]' value="Asset Management">Asset Management</option>
                                                    <option className='text-[#1b1b1b]' value="Private Equity">Private Equity</option>
                                                    <option className='text-[#1b1b1b]' value="Venture Capital">Venture Capital</option>
                                                    <option className='text-[#1b1b1b]' value="Future Capital">Future Capital</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-span-1'>
                                            <div className="">
                                                <label htmlFor="investment_Size" className="block sm:mb-2 my-2 text-sm font-medium text-[#d9d9d9]">Investment Size</label>
                                                <select onChange={(e) => { setInvestmentSize(e.target.value) }} value={investmentsize} id="investment_Size" name='investment_size' className="bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block w-full p-2.5 ">
                                                    <option defaultValue className='text-[#1b1b1b]'>Select an option</option>
                                                    <option className='text-[#1b1b1b]' value="&lt;$500K"> &lt;$500K</option>
                                                    <option className='text-[#1b1b1b]' value="$500K-$1M">$500K-$1M</option>
                                                    <option className='text-[#1b1b1b]' value="$1M-$5M">$1M-$5M</option>
                                                    <option className='text-[#1b1b1b]' value="&gt;$5M">&gt;$5M</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className='col-span-2'>
                                            <div className="">
                                                <label htmlFor="company_Overview" className="block sm:mb-2 my-2 text-sm font-medium text-[#d9d9d9]">Company/Organization Overview:</label>
                                                <textarea onChange={(e) => { setCompanyOverview(e.target.value) }} value={companyoverview} id="company_Overview" name='organization_overview' rows="3" className="bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block w-full p-2.5 " placeholder="Briefly describe your company/organization, including its mission, product/service, and current operations." />
                                            </div>
                                        </div>
                                        <div className='col-span-2'>
                                            <div className="">
                                                <label htmlFor="investment_Purpose" className="block sm:mb-2 my-2 text-sm font-medium text-[#d9d9d9]">Investment Purpose & Use of Funds:</label>
                                                <textarea onChange={(e) => { setInvestmentPurpose(e.target.value) }} value={investmentpurpose} id="investment_Purpose" name='investment_purpose' rows="3" className="bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block w-full p-2.5 " placeholder="Provide a succinct overview of how the investment will be utilized and the expected impact on your company/organization." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full h-[.0625rem] bg-[#d9d9d978] my-[1.375rem]'></div>
                            <div className='md:grid md:grid-cols-2'>
                                <div className='col-span-1'>
                                    <p className='sm:text-[1.5rem] text-[1.125rem] text-[#d9d9d9] font-medium mb-[.5rem]'>Additional Attachments</p>
                                </div>
                                <div className='col-span-2'>
                                    <div className='md:grid md:grid-cols-3 gap-[1rem]'>
                                        <div className='col-span-1'>
                                            <div className="">
                                                <label htmlFor="business_Plan" className="block sm:mb-2 my-2 text-sm font-medium text-[#d9d9d9]">Business Plan</label>
                                                <input type="file" onChange={(e) => { setBusinessPlan(e.target.value) }} value={businessplan} id="business_Plan" name='business_plan' className="bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block w-full p-2.5" placeholder="Enter your text" />
                                            </div>
                                        </div>
                                        <div className='col-span-1'>
                                            <div className="">
                                                <label htmlFor="financial_Statements" className="block sm:mb-2 my-2 text-sm font-medium text-[#d9d9d9]">Financial Statements</label>
                                                <input type="file" onChange={(e) => { setFinancialStatements(e.target.value) }} value={financialstatements} id="financial_Statements" name='financial_statements' className="bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block w-full p-2.5" placeholder="Enter your text" />
                                            </div>
                                        </div>
                                        <div className='col-span-1'>
                                            <div className="">
                                                <label htmlFor="investment_Deck" className="block sm:mb-2 my-2 text-sm font-medium text-[#d9d9d9]">Investment Deck</label>
                                                <input type="file" onChange={(e) => { setInvestmentDeck(e.target.value) }} value={investmentdeck} id="investment_Deck" name='investment_deck' className="bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block w-full p-2.5" placeholder="Enter your text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full h-[.0625rem] bg-[#d9d9d978] my-[1.375rem]'></div>
                            <div className='md:grid md:grid-cols-2'>
                                <div className='col-span-1'>
                                    <p className='sm:text-[1.5rem] text-[1.125rem] text-[#d9d9d9] font-medium mb-[.5rem]'>Legal Acknowledgement</p>
                                </div>
                                <div className='col-span-2'>
                                    <div className="flex items-start " onClick={checkHandler}>
                                        <input defaultChecked={modeToggle} type="checkbox" id="checkbox" className="w-[1rem] h-[1rem] mt-[.1875rem] bg-transparent border border-[#d9d9d978] text-[#d9d9d9] text-sm rounded-lg block p-2.5" placeholder="Enter your text" required />
                                        <label htmlFor="checkbox" className="w-[calc(100%-1rem)] ms-2 block text-sm font-medium text-[#d9d9d9]">I hereby confirm that all the information provided is accurate and true to the best of my knowledge. I understand that any misrepresentation may result in disqualification from investment consideration.</label>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full h-[.0625rem] bg-[#d9d9d978] my-[1.375rem]'></div>
                            <div className='flex justify-end'>
                                <button type="submit" className="md:text-[1.5rem] text-[1.125rem]  bg-transparent border border-[#d9d9d978] md:px-[2rem] px-[1.25rem] md:py-[.5rem] py-[.375rem] text-[#d9d9d9] whitespace-nowrap hover:text-[#fbfbfb] transition-all duration-300">
                                    {isloading == false ? <span>Submit</span> : <><svg className='w-[60px] h-[30px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><circle fill="#0A1439" stroke="#0A1439" strokeWidth="15" r="15" cx="40" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="#0A1439" stroke="#0A1439" strokeWidth="15" r="15" cx="100" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="#0A1439" stroke="#0A1439" strokeWidth="15" r="15" cx="160" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg></>}
                                </button>
                            </div>
                        </div>
                    </div>)
                }
            </form>
        </div>
    )
}
