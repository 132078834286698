import React from 'react'

export default function Footer() {
  return (
    <div className=''>
        <div className='text-center'>
            <p className='sm:text-[18px] text-[14px] leading-[1.8] text-[#1e1e1e]'>Copyright © 2023 kapitalbe.com - All Rights Reserved.</p>
        </div>
    </div>
  )
}
