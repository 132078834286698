import React, { useEffect, useRef } from 'react'
import video from '../../video/home-video.mp4'
import about_us from '../../image/about_us.webp'
import vanguard_Image from '../../image/Vanguard Image.webp'
import law from '../../image/Tenets of Family Values & Governance.webp'
import Blog from '../../Components/Blog';
import Footer from '../../Components/Footer';
import Blog_1 from '../../image/Asset Mastery.webp'
import Blog_2 from '../../image/Private Equity Pursuits.webp'
import Blog_3 from '../../image/Venture Capital.webp'
import Blog_4 from '../../image/Pioneering the future.webp'
import Blog_5 from '../../image/Tenets of Family Values & Governance.webp'
import Form from '../Form';

export default function Home() {
    const vidRef = useRef();
    useEffect(() => {
        vidRef.current.play = true;
    }, []);

    return (
        <div>
            <div className="container mx-auto 2xl:px-[100px] 2lg:px-[40px] sm:px-[20px] px-[10px]">
                <div className='heading p-[1rem]'>
                    <h1 className='text-black text-center md:text-[64px] sm:text-[48px] text-[36px] font-extrabold tracking-[7px]'>KAPITAL B</h1>
                </div>
                <div className='px-[1rem]'>
                    <video src={video} ref={vidRef} autoPlay muted ></video>
                    <p className='text-[md:18px] py-[30px] text-center text-[#1e1e1e]'>Welcome to KapitalB, the distinguished family office of the Babay family</p>
                </div>
                <div className='2xl:px-[100px] px-[1rem]'>
                    <div className='md:py-[30px] pb-[30px]'>
                        <h3 className='text-center md:text-[36px] sm:text-[30px] text-[24px] font-medium text-[#1b1b1b] md:mb-[36px] mb-[24px]'>About Us</h3>
                        <div className='grid md:grid-cols-3 grid-cols-1 md:gap-[24px]'>
                            <div className='md:col-span-1 pt-[24px] order-2'>
                                <div className='md:mb-[40px] mb-[20px]'>
                                    <p className='sm:text-[18px] font-medium text-[#1e1e1e]'>Preserving Legacies Amidst Global Evolution</p>
                                    <p className='sm:text-[18px] 2lg:leading-[1.8] leading-[1.4] text-[#1e1e1e]'>In a world that relentlessly transforms, we stand unwavering, shepherding our family's wealth and legacy, ensuring they flourish synchronously with the shifting times.</p>
                                </div>
                                <div className='md:mb-[40px] mb-[20px]'>
                                    <p className='sm:text-[18px] font-medium text-[#1e1e1e]'>Our Heritage</p>
                                    <p className='sm:text-[18px] 2lg:leading-[1.8] leading-[1.4] text-[#1e1e1e]'>Originating from the historically rich and culturally diverse landscapes of the Middle East, our lineage encapsulates a tapestry of resilience, profound wisdom, and intrinsic adaptability. The compass of our Family Office has elegantly maneuvered through the undulating terrains of global transitions, ensuring our legacy's luminance amid changing epochs.</p>
                                </div>
                                <div className='md:mb-[40px] mb-[20px]'>
                                    <p className='sm:text-[18px] font-medium text-[#1e1e1e]'>The Art of Adaptation</p>
                                    <p className='sm:text-[18px] 2lg:leading-[1.8] leading-[1.4] text-[#1e1e1e]'>The Kapital B Family Office has eternally recognized the profound strength of metamorphosis. With poised navigation through global transformations, we have ensured an unbroken continuum of prosperity and legacy.</p>
                                </div>
                                <div className='md:mb-[40px] mb-[20px]'>
                                    <p className='sm:text-[18px] font-medium text-[#1e1e1e]'>Philosophical Pillars</p>
                                    <p className='sm:text-[18px] 2lg:leading-[1.8] leading-[1.4] text-[#1e1e1e]'>With each passing generation and the ever-evolving global panorama, our philosophy is to mature alongside. We ardently preserve the cardinal virtues treasured by our lineage while contemporaneously refining our strategies to remain at the forefront of the dynamic financial vanguard.</p>
                                </div>
                            </div>
                            <div className='col-span-2 md:order-2'>
                                <img className='h-full object-cover' src={about_us} alt="about-us" />
                            </div>
                        </div>
                    </div>
                    <div className='md:py-[30px] pb-[30px]'>
                        <h3 className='text-center md:text-[36px] sm:text-[30px] text-[24px] font-medium text-[#1b1b1b] md:mb-[36px] mb-[24px]'>Our Vanguard Approach</h3>
                        <div className='grid md:grid-cols-3 grid-cols-1 md:gap-[24px]'>
                            <div className='col-span-2'>
                                <img className='h-full object-cover' src={vanguard_Image} alt="about-us" />
                            </div>
                            <div className='col-span-1 pt-[24px]'>
                                <div className='md:mb-[40px] mb-[20px]'>
                                    <p className='sm:text-[18px] font-medium text-[#1e1e1e]'>Staying Ahead of Time's Tide</p>
                                    <p className='sm:text-[18px] 2lg:leading-[1.8] leading-[1.4] text-[#1e1e1e]'>Harnessing state-of-the-art analytical tools and a discerning perspective on global undercurrents, we forecast and adeptly maneuver through shifts, assuring our family's assets always bask in growth and protection.</p>
                                </div>
                                <div className='md:mb-[40px] mb-[20px]'>
                                    <p className='sm:text-[18px] font-medium text-[#1e1e1e]'>An Investment Mélange</p>
                                    <p className='sm:text-[18px] 2lg:leading-[1.8] leading-[1.4] text-[#1e1e1e]'>In a world as variegated as ours, our investment portfolio mirrors this rich tapestry. We judiciously diversify across classical assets and avant-garde avenues, ensuring robustness against the kaleidoscope of global alterations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='md:py-[30px] pb-[30px]'>
                        <h3 className='text-center md:text-[36px] sm:text-[30px] text-[24px] font-medium text-[#1b1b1b] md:mb-[36px] mb-[24px]'>Tenets of Family Values & Governance</h3>
                        <div className='grid md:grid-cols-3 grid-cols-1 md:gap-[24px] items-center'>
                            <div className='col-span-1 pt-[24px] order-2'>
                                <div className='md:mb-[40px] mb-[20px]'>
                                    <p className='sm:text-[18px] font-medium text-[#1e1e1e]'>Enduring Foundations with Flexibility</p>
                                    <p className='sm:text-[18px] 2lg:leading-[1.8] leading-[1.4] text-[#1e1e1e]'>Our cherished family values form the bedrock of our decisions. As we recalibrate to the global milieu, these time-honored tenets remain our guiding constellations</p>
                                </div>
                                <div className='md:mb-[40px] mb-[20px]'>
                                    <p className='sm:text-[18px] font-medium text-[#1e1e1e]'>Adaptive Governance</p>
                                    <p className='sm:text-[18px] 2lg:leading-[1.8] leading-[1.4] text-[#1e1e1e]'>Our governance mechanisms fluidly metamorphose, mirroring our family's aspirations, the spirit of the era, and the broader global narrative. This agility fortifies our unity and unwavering focus on collective visions.</p>
                                </div>
                            </div>
                            <div className='col-span-2 md:order-2'>
                                <img className='h-full object-cover' src={law} alt="about-us" />
                            </div>
                        </div>
                    </div>
                    {/* <div className='md:py-[30px] pb-[30px]'>
                        <h3 className='text-center md:text-[36px] sm:text-[30px] text-[24px] font-medium text-[#1b1b1b] mb-[8px]'>Subscribe</h3>
                        <p className='sm:text-[18px] text-center text-[#1e1e1e] mb-[1rem]'>Sign up to be the first to know about our soft launch events.</p>
                        <div className=''>
                            <form className="2lg:px-[200px] md:px-[100px] mx-auto">
                                <div className="flex gap-[20px] items-center">
                                    <div className='relative z-0 w-full'>
                                        <input type="email" name="floating_email" id="floating_email" className="h-[55px] block w-full sm:text-[18px] text-[#474747] pl-[8px] bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border focus:pt-[1rem] focus:border-[#1b1b1b] peer " placeholder=" " required />
                                        <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-[#6f6f6f] duration-300 transform -translate-y-6 scale-75 top-[26px] pl-[8px] -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email</label>
                                    </div>
                                    <div>
                                        <button type="submit" className="md:text-[24px] sm:text-[18px] rounded-[8px] bg-[#6f6f6f] md:px-[32px] px-[20px] md:py-[8px] py-[6px] text-white whitespace-nowrap hover:bg-[#888]">Sign up</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div> */}
                    <div className='md:py-[30px] pb-[30px]'>
                        <h3 className='text-center md:text-[36px] sm:text-[30px] text-[24px] font-medium text-[#1b1b1b] mb-[8px]'>Investment Paradigms</h3>
                        <p className='sm:text-[18px] text-center text-[#1e1e1e] mb-[1rem] md:w-[65%] mx-auto'>Our steadfast capital is judiciously dispersed across four pivotal strategies: Asset Management, Private Equity, Venture Capital, and Future Capital.</p>
                        <div className='grid md:grid-cols-3 sm:grid-cols-2 md:gap-[48px] gap-[24px] justify-center'>
                            <div>
                                <Blog img={Blog_1} title='Asset Mastery' descripton="Empowered by profound market acumen and an intricate analytical scaffold, our Asset Management ethos optimizes the richness of our diverse portfolio. With vigilant oversight of global financial vistas, we ensure our investments are shielded while poised for ascension across both conventional and novel markets." />
                            </div>
                            <div>
                                <Blog img={Blog_2} title='Private Equity Pursuits' descripton="The nucleus of our Private Equity strategy is the relentless quest for intrinsic value. We alight upon and ally with burgeoning enterprises, catalyzing their zenith potential. Through sagacious guidance, operational prowess, and capital fortification, we sculpt lasting value, championing growth and renaissance." />
                            </div>
                            <div>
                                <Blog img={Blog_3} title='Venture Catalysts' descripton="Our Venture Capital schema is the crucible of innovation and enterprising vigor. Espousing nascent enterprises with disruptive visions and scalable architectures, we infuse both capital and sagacity, nurturing them to burgeon into tomorrows industry vanguards." />
                            </div>
                            <div className='md:block hidden'></div>
                            <div className=''>
                                <Blog img={Blog_4} title='Pioneering the Future' descripton="Future Capital epitomizes our vision-forward strategy, accentuating sectors and tech vanguards that will sculpt forthcoming epochs. From sustainable energy odysseys to cutting-edge artificial intelligence tapestries, we endorse ventures poised to architect the future, ensuring our portfolio's enduring relevance amidst rapid technological renaissance." />
                            </div>
                            <div className='md:block hidden'></div>
                        </div>
                    </div>
                    <div className='md:py-[30px] pb-[30px]'>
                        <Form />
                    </div>
                    <div className='md:py-[30px] pb-[30px]'>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    )
}
