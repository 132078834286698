import React from 'react'


function Blog(props) {
  return (
    <div className=''>
      <div className='text-center'>
        <p className='text-[20px] text-[#1b1b1b] mb-[24px]'>{props.title}</p>
        <div className='flex justify-center'>
          <img className='mb-[24px] h-[200px] w-full object-cover' src={props.img} alt="blog-img" />
        </div>
        <p className='sm:text-[18px] 2lg:leading-[1.8] leading-[1.4] text-[#1e1e1e]'>{props.descripton}</p>
      </div>
    </div>
  )
}

export default Blog